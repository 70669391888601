
<div class="content" #contentReference>
  <div class="github-links">
    <a
      href="https://github.com/getquerydb/docs.querydb.io/edit/master/content/support.md"
      aria-label="Suggest Edits"
      title="Suggest Edits"
    >
      <i class="fas fa-edit"></i>
    </a>
  </div>
  <h3 id="support">Support</h3>
<p>QueryDB is an MIT-licensed open source project with its ongoing development made possible thanks to the support by the community. This framework is a result of the long road, full of sleepless nights, working <strong>after hours</strong>, and busy weekends.</p>
<h4 appAnchor id="how-can-you-help"><span>How can you help?</span></h4>
<p>QueryDB doesn&#39;t have a large company that sits behind and is continuously paying for hours spent on the development. I fully rely on the <strong>goodness</strong> ❤️ of the people. However, I would love to make this framework even more <strong>powerful</strong>, to be fully focused on delivering you great solutions that make coding process enjoyable: In order to help me, I run few supporting platforms:</p>
<ul>
<li>or reach me directly: <a href="mailto:reyiclaros@gmail.com">reyiclaros@gmail.com</a></li>
</ul>
<p>If you fell in love with QueryDB, or you run a business which is using QueryDB, consider sponsoring its development to ensure that the project which your product relies on is <strong>actively maintained</strong> and improved. Also, your support could help me to work more on content that benefits whole QueryDB community, writing either educational blog posts or recording videos.</p>

</div>

