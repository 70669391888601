<footer>
  <div class="social-wrapper">
    <a
      title="Oficial GitHub"
      href="https://github.com/getquerydb"
      target="_blank"
      ><i class="fab fa-github"></i
    ></a>
    <a
      href="https://querydb.io"
      title="QueryDB - Query Any Database and Export to API Rest"
      target="_blank"
      ><i class="fa fa-globe"></i
    ></a>
  </div>
  <div class="credits">
    <p>
      Copyright © 2020 MIT by
      <a
        href="https://reynaldoclaros.com"
        target="_blank"
        title="Full-Stack Software Engineer"
      >
        QueryDB</a
      ><span class="separator"></span>
    </p>
  </div>
</footer>
