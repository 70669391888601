
<div class="content" #contentReference>
  <div class="github-links">
    <a
      href="https://github.com/getquerydb/docs.querydb.io/edit/master/content/introduction.md"
      aria-label="Suggest Edits"
      title="Suggest Edits"
    >
      <i class="fas fa-edit"></i>
    </a>
  </div>
  <h3 id="introduction">Introduction</h3>
<p>QueryDB is a tool to allow anyone to take advantage of the power of large and small data, QueryDB allows connecting to multiple databases, exploring and executing complex queries according to the language of the database engine, this data can be exported to many format as JSON.</p>
<p>Connect Relational Databases
Explore and Execute Complex query
Export to API REST</p>
<h4 appAnchor id="querydb-features"><span>QueryDB Features!</span></h4>
<ul>
<li><strong>Browser-based</strong> : Everything in your browser.</li>
<li><strong>Ease-of-use</strong> : Become immediately productive with data without the need to master complex software.</li>
<li><strong>Query editor</strong> : Quickly compose SQL and NoSQL queries with a schema browser and auto-complete.</li>
<li><strong>Expression Language</strong> : It´s use  <a rel='nofollow' target='_blank' href="https://docs.spring.io/spring-framework/docs/3.0.x/reference/expressions.html">Spring Expression Language</a> to make complex rules to querys SQL and NoSQL.</li>
<li><strong>REST API</strong> : Everything that can be done in the UI is also available through REST API</li>
<li><strong>Broad support for data sources</strong> : Extensible data source API with native support for a long list of common databases and platforms</li>
</ul>
<h4 appAnchor id="supported-databases"><span>Supported Databases</span></h4>
<p>QueryDB supports relational datasources, it can also be extended to support more. Below is a lis of build-in sources.
<a routerLink="/providers/overview">See Here</a></p>
<h4 appAnchor id="docker"><span>Docker</span></h4>
<p>QueryDB is very easy to install and deploy in a Docker container.</p>
<p>By default, the Docker will expose port 8080, so change this within the Dockerfile if necessary. When ready, simply use the Dockerfile to build the image.</p>
<pre><code class="language-sh">
docker build -t querydb/querydb:$&#123;package.json.version&#125; .</code></pre>
<h4 appAnchor id="reporting-bugs-and-contributing-code"><span>Reporting Bugs and Contributing Code</span></h4>
<ul>
<li>Want to report a bug or request a feature? Please open <a rel='nofollow' target='_blank' href="https://github.com/getquerydb/querydb/issues/new">an issue.</a></li>
<li>Want to help us build QueryDB? Fork the project, edit in a dev environment and make a pull request. We need all the help we can get!</li>
</ul>
<h4 appAnchor id="security"><span>Security</span></h4>
<p>Please email <a href="mailto:&#114;&#101;&#121;&#105;&#99;&#108;&#x61;&#x72;&#x6f;&#115;&#x40;&#x67;&#109;&#97;&#x69;&#x6c;&#x2e;&#99;&#x6f;&#x6d;">&#114;&#101;&#121;&#105;&#99;&#108;&#x61;&#x72;&#x6f;&#115;&#x40;&#x67;&#109;&#97;&#x69;&#x6c;&#x2e;&#99;&#x6f;&#x6d;</a> to report any security vulnerabilities. We will acknowledge receipt of your vulnerability and strive to send you regular updates about our progress</p>
<h2 id="license">License</h2>
<p>BSD-2-Clause.</p>

</div>

