
<div class="content" #contentReference>
  <div class="github-links">
    <a
      href="https://github.com/getquerydb/docs.querydb.io/edit/master/content/plugin.md"
      aria-label="Suggest Edits"
      title="Suggest Edits"
    >
      <i class="fas fa-edit"></i>
    </a>
  </div>
  <h3 id="plugin">Plugin</h3>
<p>QueryDB is a tool to allow anyone to take advantage of the power of large and small data, QueryDB allows connecting to multiple data sources, exploring and executing complex queries according to the language of the database engine, this data can be exported to many format as JSON, XML formats among others.</p>
<p>Connect Relational Databases
Explore and Execute Complex query
Export to API REST</p>

</div>

