<div class="social-wrapper">
  <a href="https://github.com/getquerydb/querydb" target="_blank">
    <i class="fab fa-github"></i>
  </a>
  <a
    href="https://querydb.com"
    title="QueryDB - Query Any Database and Export to API Rest"
    target="_blank"
  >
    <i class="fa fa-globe"></i>
  </a>
</div>
