import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plugin',
  templateUrl: './plugin.component.html'
})
export class PluginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
